import React from "react";
import LazyCom from "./components/LazyCom";

// import EerrorPage from './EerrorPage';
const EerrorPage = React.lazy(() => import("./EerrorPage"));

const LazyEerrorPage = () => {
  return (
    <LazyCom>
      <EerrorPage />
    </LazyCom>
  );
};

export default LazyEerrorPage;
