import React from "react";
import LazyCom from "../../components/LazyCom";

const Contact = React.lazy(() => import("./Contact"));

const LazyContact = () => {
  return (
    <LazyCom>
      <Contact />
    </LazyCom>
  );
};

export default LazyContact;
