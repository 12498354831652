import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LazyNavbar from "./components/Navbar/LazyNavbar";
import LazyHome from "./pages/Home/LazyHome";
import LazyAbout from "./pages/About/LazyAbout";
import LazyOurVision from "./pages/OurVision/LazyOurVision";
import LazySustainability from "./pages/Sustainability/LazySustainability";
import LazyInnovation from "./pages/Innovation/Innovation";
import LazyStrategy from "./pages/Strategy/LazyStrategy";
import LazyServices from "./pages/Services/LazyServices";
import LazyCollab from "./pages/CollaborationPart/LazyCollab";
import LazyContact from "./pages/Contact/LazyContact";
import LazyFooter from "./components/Footer/LazyFooter";
import LazyEerrorPage from "./LazyEerrorPage";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <LazyNavbar></LazyNavbar>
        <Routes>
          <Route path="/" element={<LazyHome />} />
          <Route path="/about" element={<LazyAbout />} />
          <Route path="/ourVision" element={<LazyOurVision />} />
          <Route path="/sustainability" element={<LazySustainability />} />
          <Route path="/innovation" element={<LazyInnovation />} />
          <Route path="/Strategy" element={<LazyStrategy />} />
          <Route path="/services" element={<LazyServices />} />
          <Route path="/collab" element={<LazyCollab />} />
          <Route path="/contact" element={<LazyContact />} />
          <Route path="*" element={<LazyEerrorPage />} />
        </Routes>
        <LazyFooter></LazyFooter>
      </BrowserRouter>
    </div>
  );
}

export default App;
