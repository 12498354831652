import React from "react";
import LazyCom from "../../components/LazyCom";

const About = React.lazy(() => import("./About"));

const LazyAbout = () => {
  return (
    <LazyCom>
      <About />
    </LazyCom>
  );
};

export default LazyAbout;
