import React, { Suspense } from "react";
import Lottie from "lottie-react";
import Animations from "./Animations.json";

const LazyCom = ({ children }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animations,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            width: "100vw", // Full viewport width
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
            // background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            background: "#fff", // Semi-transparent background
          }}
        >
          <Lottie
            options={defaultOptions}
            height={50} // Adjust height as needed
            width={50} // Adjust width as needed
            animationData={Animations}
          />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export default LazyCom;
