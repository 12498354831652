import React from "react";
import LazyCom from "../../components/LazyCom";
// import Strategy from "./Strategy";

// import Customers from './Customers';
const Strategy = React.lazy(() => import("./Strategy"));

const LazyStrategy = () => {
  return (
    <LazyCom>
      {/* <Customers /> */}
      <Strategy />
    </LazyCom>
  );
};

export default LazyStrategy;
