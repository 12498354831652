import React from "react";
import LazyCom from "../../components/LazyCom";

const Services = React.lazy(() => import("./Services"));

const LazyServices = () => {
  return (
    <LazyCom>
      <Services />
    </LazyCom>
  );
};

export default LazyServices;
