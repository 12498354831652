import React from "react";
import LazyCom from "../../components/LazyCom";

const Home = React.lazy(() => import("./Home"));

const LazyHome = () => {
  return (
    <LazyCom>
      <Home />
    </LazyCom>
  );
};

export default LazyHome;
