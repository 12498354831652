import React from "react";
import LazyCom from "../../components/LazyCom";

// import CollaborationPart from "./CollaborationPart";
const CollaborationPart = React.lazy(() => import("./CollaborationPart"));

const LazyCollab = () => {
  return (
    // <div>LazyCollab</div>
    <LazyCom>
      <CollaborationPart />
    </LazyCom>
  );
};

export default LazyCollab;
