import React from "react";
import LazyCom from "../../components/LazyCom";

const OurVision = React.lazy(() => import("./OurVision"));

const LazyOurVision = () => {
  return (
    <LazyCom>
      <OurVision />
    </LazyCom>
  );
};

export default LazyOurVision;
