import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./innovation.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

import innovationSimg1 from "../../images/innovation-simg2.jpg";
import innovationSimg2 from "../../images/innovation-simg1.jpg";
import innovationSimg3 from "../../images/innovation-simg3.jpg";
import innovationSimg4 from "../../images/innovation-img4.jpg";
import innovationSimg5 from "../../images/innovation-img5.png";
import innovationSimg6 from "../../images/innovation-img6.jpg";

const Innovation = () => {
  return (
    <div className="innvation">
      <div className="mb-5">
        <Swiper
          className="swiper-wrapper"
          modules={[Autoplay, Pagination]}
          rewind={true}
          loop={true}
          spaceBetween={30}
          speed={300}
          centeredSlides={true}
          mousewheel
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
        >
          <SwiperSlide className="swiper-slide">
            <img
              src={innovationSimg1}
              alt=""
              className="img-fluid zoom-image"
              style={{ height: "600px", width: "100%" }}
            />
          </SwiperSlide>

          <SwiperSlide className="swiper-slide">
            <img
              src={innovationSimg2}
              alt=""
              className="img-fluid zoom-image"
              style={{ height: "600px", width: "100%" }}
            />
          </SwiperSlide>

          <SwiperSlide className="swiper-slide">
            <img
              src={innovationSimg3}
              alt=""
              className="img-fluid zoom-image"
              style={{ height: "600px", width: "100%" }}
            />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="container mb-5">
        <div className="d-flex justify-content-between align-items-center hey1">
          <div style={{ width: "50%" }} className="img-part">
            <img src={innovationSimg4} className="product-img" alt="" />
          </div>

          <div style={{ width: "50%" }} className="text-part2 rrr">
            <h2 className="text-center">Place Holder</h2>
            <p className="text-center">Text</p>
          </div>
        </div>

        <hr />

        <div className="d-flex justify-content-between align-items-center hey">
          <div style={{ width: "50%" }} className="text-part">
            <h2 className="text-center">Place Holder</h2>
            <p className="text-center">Text</p>
          </div>

          <div style={{ width: "50%" }} className="img-part">
            <img src={innovationSimg5} className="product-img" alt="" />
          </div>
        </div>

        <hr />

        <div className="d-flex justify-content-between align-items-center hey1">
          <div style={{ width: "50%" }} className="img-part">
            <img src={innovationSimg6} className="product-img" alt="" />
          </div>

          <div style={{ width: "50%" }} className="text-part2 rrr">
            <h2 className="text-center">Place Holder</h2>
            <p className="text-center">Text</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Innovation;
