import React from "react";
import LazyCom from "../LazyCom";

const Footer = React.lazy(() => import("./Footer"));

const LazyFooter = () => {
  return (
    <LazyCom>
      <Footer />
    </LazyCom>
  );
};

export default LazyFooter;
