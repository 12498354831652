import React from "react";
import LazyCom from "../../components/LazyCom";

const Sustainability = React.lazy(() => import("./Sustainability"));

const LazySustainability = () => {
  return (
    <LazyCom>
      <Sustainability />
    </LazyCom>
  );
};

export default LazySustainability;
