import React from "react";
import LazyCom from "../LazyCom";

// import Navbar from './Navbar';
const Navbar = React.lazy(() => import("./Navbar"));

const LazyNavbar = () => {
  return (
    <LazyCom>
      <Navbar />
    </LazyCom>
  );
};

export default LazyNavbar;
